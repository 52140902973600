import React, { useEffect, useRef, useState } from "react";
import { query, collection, orderBy, onSnapshot, limit } from "firebase/firestore";
import { db } from "../firebase";
import Message from "./Message";
import SendMessage from "./SendMessage";


window.onbeforeunload = function () {
  window.scrollTo(0, 0);
};  

 
const ChatBox = () => {
  const [messages, setMessages] = useState([]);
  const chatBottomRef = useRef();

  useEffect(() => {
    const q = query(
      collection(db, "messages"),
      orderBy("createdAt", "desc"), // Order messages by createdAt in descending order
      limit(100), // Limit the number of messages to 100
    );

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const fetchedMessages = [];
      querySnapshot.forEach((doc) => {
        fetchedMessages.push({ ...doc.data(), id: doc.id });
      });
      setMessages(fetchedMessages.reverse()); // Reverse the order of messages to display them from bottom to top
      scrollToBottom();
    });

    return () => {
      unsubscribe();
    };
  }, []);

  const scrollToBottom = () => {
    const messagesWrapper = chatBottomRef.current.parentElement;
    messagesWrapper.scrollTop = messagesWrapper.scrollHeight - messagesWrapper.clientHeight - 50; // Adjust the offset as needed
  };
  
  
  return (
    <main className="chat-box">
      <div className="messages-wrapper">
        {messages.map((message, index) => (
          <Message key={message.id} message={message} isLast={index === messages.length - 1} />
        ))}
        <div ref={chatBottomRef} /> {/* This element will be used to scroll to the bottom of the chat */}
      </div>
      
      <SendMessage sendMessageCallback={scrollToBottom} />
      </main>
  );
};

export default ChatBox;
