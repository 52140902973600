import React from "react";
import GoogleSignin from "../img/Authenticate.svg";
import { auth } from "../firebase";
import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { LinearGradient } from "react-text-gradients";

const Welcome = () => {
  const googleSignIn = () => {
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider);
  };

  return (
    <main className="welcome">
            <div className="welcome_text"> 
        <LinearGradient gradient={['to left', '#17acff ,#ff68f0']}>
          Globbink
        </LinearGradient>
      </div>
      <p>Sign in with Google to Start chatting</p>
      <br />
      
      <button className="sign-in">
        <img
          onClick={googleSignIn}
          src={GoogleSignin}
          alt="sign in with google"
          type="button"
          height={500}
          width={500}
        />
        </button>
        <div className="tos">
        <p>By signing in, you agree to our terms and services</p>
        <a href="https://policies.google.com/terms" target="_blank" rel="noreferrer">
          Learn more
        </a>
        </div>
      
    </main>
  );
};

export default Welcome;