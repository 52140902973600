// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from 'firebase/storage';
import { getDatabase } from 'firebase/database';



// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyA8SK9Yl4b3id2H9bcS5ehbvXLj7VoooYg",
    authDomain: "globbink-48f15.firebaseapp.com",
    projectId: "globbink-48f15",
    storageBucket: "globbink-48f15.appspot.com",
    messagingSenderId: "373257272672",
    appId: "1:373257272672:web:90940b4be857d33f56b205",
    measurementId: "G-4PYHWW1QN0"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
console.log("Firebase initialized");
export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);
export const rtdb = getDatabase(app); // Ensure this line is included
