import React, { useEffect, useRef } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../firebase";

const Message = ({ message }) => {
  const [user] = useAuthState(auth);
  const messageRef = useRef(null);

  useEffect(() => {
    scrollToBottom();
  }, [message]);
  

  const scrollToBottom = () => {
    if (messageRef.current) {
      messageRef.current.scrollIntoView({ behavior: "smooth", block: "end" });
    }
  };

  return (
    <div ref={messageRef} className={`chat-bubble ${message.uid === user?.uid ? "right" : ""}`}>
      <img className="chat-bubble__left" src={message.avatar} alt="user avatar" />
      <div className="chat-bubble__right">
        <p className="user-name">{message.name}</p>
        <p className="user-message">{message.text}</p>
        {message.fileURL && (
          <img src={message.fileURL} alt="Uploaded file" className="uploaded-image" />
        )}
        {message.createdAt && (
          <p className="message-time">
            {new Date(message.createdAt.toDate()).toLocaleString()}
          </p>
        )}
      </div>
    </div>
  );
};

export default Message;
