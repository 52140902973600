import React, { useRef, useState } from "react";
import Picker from "@emoji-mart/react";
import data from "@emoji-mart/data";
import { auth, db, storage } from "../firebase";
import { addDoc, collection, serverTimestamp } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { FileUploader } from "./FileUploader";

const SendMessage = ({ sendMessageCallback }) => {
  const messageRef = useRef(null);
  const [file, setFile] = useState(null);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);

  const addEmoji = (emoji) => {
    const emojiSymbol = emoji.native;
    messageRef.current.value += emojiSymbol; // Append emoji to the input field
    setShowEmojiPicker(false); // Hide emoji picker after selection
  };

  const sendMessage = async () => {
    const trimmedMessage = messageRef.current?.value.trim();

    if (!trimmedMessage && !file) {
      return;
    }

    const { uid, displayName, photoURL } = auth.currentUser;
    const messageData = {
      text: trimmedMessage || "",
      name: displayName,
      avatar: photoURL,
      createdAt: serverTimestamp(),
      uid,
    };

    try {
      if (file) {
        const fileRef = ref(storage, `uploads/${file.name}-${Date.now()}`);
        await uploadBytes(fileRef, file);
        const fileURL = await getDownloadURL(fileRef);
        messageData.fileURL = fileURL;
      }

      await addDoc(collection(db, "messages"), messageData);
      messageRef.current.value = "";
      setFile(null);
      sendMessageCallback();
    } catch (error) {
      console.error("Error sending message:", error);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      sendMessage();
    }
  };

  return (
    <div>
      <form onSubmit={(e) => e.preventDefault()} className="send-message">
        <FileUploader handleFileUpload={setFile} />
        <input
          ref={messageRef}
          type="text"
          className="form-input__input"
          placeholder="Type your message here..."
          onKeyDown={handleKeyDown}
        />
        <button type="button" onClick={() => setShowEmojiPicker(!showEmojiPicker)}>
          😊
        </button>
        <button type="button" onClick={sendMessage}>
          Send
        </button>
      </form>
      {showEmojiPicker && (
        <div className="emoji-picker">
        <Picker data={data} onEmojiSelect={addEmoji} theme="dark" />
        </div>
      )}
    </div>
  );
};

export default SendMessage;
