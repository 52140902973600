import { useRef } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImage } from '@fortawesome/free-solid-svg-icons';

export const FileUploader = ({ handleFileUpload }) => {
  const hiddenFileInput = useRef(null);

  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  const handleChange = (event) => {
    const fileUploaded = event.target.files[0];
    console.log("File selected:", fileUploaded);
    handleFileUpload(fileUploaded);
  };
  
  
  return (
    <>
      <button className="button-upload" onClick={handleClick}>
        <FontAwesomeIcon icon={faImage} />
      </button>
      <input
        type="file"
        onChange={handleChange}
        ref={hiddenFileInput}
        accept="image/*"
        style={{ display: "none" }}
      />
    </>
  );
};
