/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import GoogleSignin from "../img/gauth.svg";
import { auth } from "../firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { GoogleAuthProvider, signInWithRedirect } from "firebase/auth";
const NavBar = () => {
  const [user, setUser] = useAuthState(auth);
  const googleSignIn = () => {
    const provider = new GoogleAuthProvider();
    signInWithRedirect(auth, provider);
  };
  const signOut = () => {
    auth.signOut();
  };
  return (
    <nav className="nav-bar">
      <h1>Globbink</h1> 
      {user ? (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <a onClick={signOut} className="sign-out" type="button">
          Sign Out
        </a>
      ) : (
        <button className="sign-in">
          <img
            onClick={googleSignIn}
            src={GoogleSignin}
            alt="sign in with google"
            type="button"
          />
        </button>
      )}
    </nav>
  );
};
export default NavBar